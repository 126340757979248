.App {
  text-align: center;
  vertical-align: middle;
}

.App-font {
  font-family: brandon-grotesque, sans-serif;
  letter-spacing: 1px;
  color: #2f3035;
}

.App-header {
  background-color: #6fddda;
  min-height: 100vh;
  white-space: nowrap;
}

.div-flex {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.div-flex-child {
  position: absolute;
  align-self: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 7vh;
}

.description {
  font-size: 5vmin;
}

.logo {
  height: 10vh;
}

.footer {
  position: absolute;
  align-self: center;
  bottom: 1vh;
  font-size: 2vh;
}

.blue {
  background-color: blue;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

a {
  color: #2f3035;
}

@font-face {
  font-family: brandon-grotesque;
  font-weight: black;
  src: url("./Brandon_blk.otf") format("opentype");
}
